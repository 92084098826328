<template>
  <div>
    <HeroSection id="hero" />
    <StoreSection id="store" />
    <VideosSection id="videos" />
    <Footer />
  </div>
</template>

<script>
import Vue from "vue";

import HeroSection from "@/components/HeroSection";
import StoreSection from "@/components/StoreSection";
import VideosSection from "@/components/VideosSection";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: {
    HeroSection,
    StoreSection,
    VideosSection,
    Footer,
  },
  methods: {},
  watch: {
    $route(to) {
      if (to.query.scroll) {
        this.$scrollTo("#" + to.query.scroll);
      }
    },
  },
  mounted() {
    let qry = this.$route.query;
    if (qry.scroll) {
      this.$scrollTo("#" + qry.scroll);
    }
    if (qry.checkout === "success") {
      Vue.notify({
        group: "toast",
        type: "success",
        title: "Votre commande a bien été enregistrée",
      });
    }
  },
};
</script>
