<template>
  <section class="store-section">
    <span class="title">STORE</span>

    <div class="grid-section">
      <div v-for="product in products" :key="product.id">
        <StoreItem class="item" :product="product" />
      </div>

    </div>
  </section>
</template>


<script>
import StoreItem from "./SubComponent/StoreItem"
export default {
  name: 'StoreSection',
  components: {
    StoreItem
  },
  data() {
    return {
      products: []
    }
  },

  beforeMount() {
    fetch(this.$API + "/product").then(r => r.json()).then(r => r.data)
    .then(array => {

      this.products = array
    })
  }
}
</script>


<style scoped>

.store-section {
  background-color: white;
}

@media (min-width: 1220px) {
  .store-section {

  }
}

.grid-section {
  padding: 3rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 25px;
  align-items: center;
  justify-items: center;
}

@media (min-width: 610px) {
  .grid-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1220px) {
  .grid-section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 25px;
    align-items: center;
    justify-items: center;
  }
}

.title {
  margin: 1rem;
  font-size: 2rem;
}

</style>