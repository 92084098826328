<template>
  <div class="store-item" v-bind:style="{backgroundImage: `url('${product.images[0]}')` }" @click="details">
  </div>
</template>


<script>
export default {
  name: 'StoreItem',
  props: ['product'],
  data() {
    return {

    }
  },
  methods: {
    details() {
      this.$router.push({ path: "/product/" + this.product.id });
    }
  }
}
</script>



<style scoped>
.store-item {
  cursor: pointer;
  background: 50% 50% no-repeat; /* 50% 50% centers image in div */
  background-size: cover;
  width: 250px;
  height: 250px;
}

.store-item:hover {
  -webkit-animation: scale-up-center 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}


</style>