<template>
  <section class="video-section">
    <span class="title">VIDEOS</span>
    <div class="grid-videos">
      <iframe :width="windowWidth < 600 ? 560 / 2 : 560" :height="windowWidth < 600 ? 315 / 2 : 315" src="https://www.youtube-nocookie.com/embed/K9Jon2QAoWE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe :width="windowWidth < 600 ? 560 / 2 : 560" :height="windowWidth < 600 ? 315 / 2 : 315" src="https://www.youtube-nocookie.com/embed/fE9ZX_kZNsY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe :width="windowWidth < 600 ? 560 / 2 : 560" :height="windowWidth < 600 ? 315 / 2 : 315" src="https://www.youtube-nocookie.com/embed/W_oCjVn2Yeg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      txt: ''
    }
  },

  watch: {
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style scoped>
.video-section {
  background-color: black;
  color: white;
}
.video-section .title {
  margin: 1rem;
  font-size: 2rem;
}

.grid-videos {
  display: grid;
  justify-items: center;
  align-items: center;
}

.grid-videos * {
  padding: 2rem;
}

</style>